import { useState } from 'react'
import { Modal } from 'components'
import { useEffect } from 'react'
import moment from 'moment/moment'
import Label from 'components/forms/label'
import Input from 'components/forms/input'
import NoRedirectPagination from 'components/no-redirect-pagination'
import { useRef } from 'react'
import InputDate from 'components/forms/input-date'
import { lang } from "config"
import useEStatementDetails from 'repositories/e-statement-detail'
import { PlusIcon } from 'components/icons'

const usePrevious = (value) => {
    const ref = useRef()

    useEffect(() => {
        ref.current = value
    }, [value])

    return ref.current
}

const Content = ({ data = [], batch, selected, onSelect }) => {
    const [selectedEStatement, setSelectedEStatement] = useState(selected)

    const [page, setPage] = useState(1)

    const [search, setSearch] = useState(undefined)
    const [from, setFrom] = useState(undefined)
    const [to, setTo] = useState(undefined)

    const { data: eStatementSelection, isLoading: isLoadingEStatement, mutate: mutateEStatement } = useEStatementDetails({
        page,
        status: 'outstanding',
        batch,
        paginate: 8,
        search,
        from,
        to
    })

    const prevSelectedEStatement = usePrevious(selectedEStatement)

    useEffect(() => {
        mutateEStatement()

        // if (prevSelectedEStatement !== selectedEStatement) {
        //     setPage(1)
        // }
    }, [selectedEStatement, page])

    useEffect(() => {
        setPage(1)
    }, [from, to, search])

    const handleChange = (value) => {
        if (selectedEStatement.find(row => row.number === value.number)) {
            setSelectedEStatement(selectedEStatement.filter((row) => row.number !== value.number))
        } else {
            setSelectedEStatement([...selectedEStatement, value])
        }
    }

    const handleSelect = () => {
        onSelect(selectedEStatement)
    }

    const clearSelection = () => {
        setSelectedEStatement([])
    }

    const resetFilter = (e) => {
        e.preventDefault()

        if (Object.keys(selectedEStatement).length === 0) {
            setSearch(undefined)
        }

        setFrom(undefined)
        setTo(undefined)
    }

    return (
        <div className="mt-8">
            <div className="grid grid-cols-1 gap-4 sm:grid-cols-4 md:grid-cols-9">
                <div className="col-span-2">
                    <Label htmlFor="search" value={lang.search} />
                    <Input onChange={e => setSearch(e.target.value)} value={search} id="search" />
                </div>

                <div className="col-span-2">
                    <Label htmlFor="from" value={lang.from} />
                    <InputDate id="from" maxDate={Date.parse(to)} onChange={(value) => setFrom(moment(value).format('Y-MM-DD'))} value={Date.parse(from)} selected={Date.parse(from)} />
                </div>

                <div className="col-span-2">
                    <Label htmlFor="to" value={lang.to} />
                    <InputDate id="to" minDate={Date.parse(from)} onChange={(value) => setTo(moment(value).format('Y-MM-DD'))} value={Date.parse(to)} selected={Date.parse(to)} />
                </div>

                {search && (selectedEStatement.length === 0 || from || to) && (
                    <div className="flex items-center text-xs text-neutral-700">
                        <button onClick={resetFilter} className="mt-5">{lang.reset}</button>
                    </div>
                )}
            </div>

            <div className="mt-4 overflow-x-auto border border-neutral-200 rounded-xl">
                <table className="min-w-full overflow-x-auto divide-y divide-neutral-200">
                    <thead className="bg-neutral-50 rounded-t-3xl">
                        <tr>
                            <th scope="col" className="relative px-6 py-3"><span className="sr-only">{lang.action}</span></th>
                            <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.number}</th>
                            <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.information}</th>
                            <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.amount}</th>
                            <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.transaction_type}</th>
                            <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.status}</th>
                            <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.transaction_date}</th>
                        </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-neutral-200">
                        {/* When loading */}
                        {isLoadingEStatement && (
                            <tr className="text-center">
                                <td colSpan="10" className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                    {lang.loading_data}
                                </td>
                            </tr>
                        )}

                        {/* When there are no list available */}
                        {!isLoadingEStatement && eStatementSelection?.data.length === 0 && !search && (
                            <tr className="text-center">
                                <td colSpan="10" className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                    {lang.no_data}
                                </td>
                            </tr>
                        )}

                        {/* When there are no list available on searching */}
                        {!isLoadingEStatement && eStatementSelection?.data.length === 0 && search && (
                            <tr className="text-center">
                                <td colSpan="10" className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                    {lang.no_result}
                                </td>
                            </tr>
                        )}

                        {!isLoadingEStatement && eStatementSelection?.data.length !== 0 && eStatementSelection?.data.map(row => (
                            <tr onClick={() => data.find((value) => value.number === row.number) ? null : handleChange(row)} className={`${data.find((value) => value.number === row.number) ? 'opacity-50' : 'cursor-pointer hover:bg-neutral-50'} transition select-none`} key={row.number}>
                                <td className="pl-6">
                                    <div className="flex items-center h-5">
                                        <input disabled={data.find((value) => value.number === row.number)} onChange={() => handleChange(row)} checked={selectedEStatement.length !== 0 && selectedEStatement.find((selected) => selected.number == row.number)} id="pph" name="pph" type="checkbox" className="w-4 h-4 rounded-full border-neutral-300 text-neutral-800" />
                                    </div>
                                </td>
                                <td className="px-6 py-4 text-xs font-medium text-neutral-900 whitespace-nowrap">
                                    {row.number}
                                </td>
                                <td className="px-6 py-4 text-xs font-medium text-neutral-900 whitespace-nowrap">
                                    {row.information}
                                </td>
                                <td className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                    {`${row.e_statement.currency} ${Intl.NumberFormat('id-Id').format(row.amount)}`}
                                </td>
                                <td class="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                    {row.transaction_type === 'cr' ? lang.credit : lang.debit}
                                </td>
                                <td className="px-6 py-4 text-xs whitespace-nowrap">
                                    <span className={`${row.status === 'outstanding' ? 'text-yellow-800 bg-yellow-100' : row.status === 'matched' ? 'text-green-800 bg-green-100' : 'text-neutral-800 bg-neutral-100'} capitalize inline-flex px-2 text-xs font-semibold leading-5 rounded-full`}>
                                        {row.status}
                                    </span>
                                </td>
                                <td className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                    {moment(row.transaction_date).format('MMMM D, YYYY')}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div className="flex items-start mt-8 space-x-2 text-xs">
                <button type="button" onClick={handleSelect} className="items-center px-6 py-3 text-white transition bg-neutral-800 rounded-xl active:hover:scale-90">
                    <span>{lang.select}</span>
                </button>
                {selectedEStatement.length !== 0 && (
                    <button type="button" onClick={clearSelection} className="inline-flex items-center px-4 py-3 space-x-2 transition border border-neutral-200 bg-neutral-50 rounded-xl active:hover:scale-90">
                        <span>{lang.clear}</span>
                    </button>
                )}
                <NoRedirectPagination current={eStatementSelection?.current_page} links={eStatementSelection?.links} from={eStatementSelection?.from} to={eStatementSelection?.to} total={eStatementSelection?.total} update={(value) => setPage(value)} />
            </div>
        </div>
    )
}

const SelectEStatement = ({ data, batch, selected, onSelect, error }) => {
    const [isOpen, setIsOpen] = useState(false)

    const openModal = () => setIsOpen(true)
    const closeModal = () => setIsOpen(false)

    const handleSelect = (value) => {
        onSelect(value)
        closeModal()
    }

    return (
        <>
            <button type="button" onClick={openModal} className={`${error ? 'border-red-200' : 'border-neutral-200'} inline-flex items-center px-4 py-3 space-x-2 transition border bg-neutral-50 rounded-xl active:hover:scale-90`}>
                <PlusIcon className="w-4 h-4" />
            </button>
            <Modal size="6xl" isOpen={isOpen} onClose={closeModal} title={`${lang.select} ${lang.e_statement}`} content={<Content data={data} batch={batch} selected={selected} onSelect={handleSelect} />} />
        </>
    )
}

export default SelectEStatement