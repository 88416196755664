import { Disclosure } from "@headlessui/react"
import { AdvancePaymentIcon, BranchIcon, ChevronRightIcon, ClipboardDataIcon, FileDollarIcon, LockAccessIcon, PermissionRoleIcon, RoleUserBranchIcon, TaxPaymentIcon, UserIcon, UsersIcon, VerifyPaymentIcon } from "components/icons"
import StoreIcon from "components/icons/store"
import SupplierIcon from "components/icons/supplier"
import { lang } from "config"
import { useAuth } from "contexts/auth-context"
import { AnimatePresence, motion, useAnimationControls } from "framer-motion"
import { useEffect, useState } from "react"
import { Link, useLocation } from "react-router-dom"

// Tailwind
import resolveConfig from 'tailwindcss/resolveConfig'
import defaultTheme from 'tailwindcss/defaultTheme'
import { usePage } from "contexts/page-context"

const Sidebar = ({ isSidebarOpen }) => {
    const url = useLocation().pathname
    const { can } = useAuth()
    const { configuration } = usePage()

    const fullConfig = resolveConfig(defaultTheme)

    const sidebar = useAnimationControls()

    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    })

    // Listen to the window resize event
    useEffect(() => {
        // window.onresize = () => {
        //     setWindowSize({
        //         width: window.innerWidth,
        //         height: window.innerHeight,
        //     })
        // }

        const updateWindowSizeState = () => {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight
            })
        }

        window.addEventListener('resize', updateWindowSizeState)

        return () => window.removeEventListener('resize', updateWindowSizeState)
    }, [])

    useEffect(() => {
        if (windowSize.width < parseInt(fullConfig.theme.screens.lg.replace('px', ''))) {
            if (isSidebarOpen) {
                sidebar.start({
                    x: 0,
                    zIndex: 'auto'
                })
            } else {
                sidebar.start({
                    x: '-4rem',
                    zIndex: '-1'
                })
            }
        } else {
            sidebar.set({
                x: 0,
                zIndex: 'auto'
            })
        }
    }, [isSidebarOpen, windowSize])

    return (
        <motion.div animate={sidebar} transition={{ type: 'spring', damping: 20 }} className="flex flex-col w-0 max-h-screen pt-12 space-y-16 font-sans lg:w-fit">
            <div className="ml-12 text-3xl">
                {process.env.REACT_APP_NAME}
            </div>
            <nav className="w-56 max-h-full px-4 pb-8 ml-8 space-y-8 overflow-x-visible overflow-y-auto">
                <div className="space-y-2">
                    <div className="text-xs">
                        {lang.pages}
                    </div>
                    <ul>
                        <li>
                            <Link to="/" state={{ back: null, from: null, transition: 'fade' }} className="flex items-center space-x-4">
                                <div className={`${url === '/' ? 'bg-neutral-900 ring-[1.5px] ring-offset-2 ring-neutral-900' : 'bg-neutral-700'} p-2 text-white rounded-full`}>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" width={24} height={24} viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                        <path d="M19 8.71l-5.333 -4.148a2.666 2.666 0 0 0 -3.274 0l-5.334 4.148a2.665 2.665 0 0 0 -1.029 2.105v7.2a2 2 0 0 0 2 2h12a2 2 0 0 0 2 -2v-7.2c0 -.823 -.38 -1.6 -1.03 -2.105"></path>
                                        <path d="M16 15c-2.21 1.333 -5.792 1.333 -8 0"></path>
                                    </svg>
                                </div>
                                <div>
                                    {lang.title.dashboard}
                                </div>
                                {url === '/' && (<div className="flex-grow border-t border-neutral-900"></div>)}
                            </Link>
                        </li>
                    </ul>
                </div>
                {(can('petty-cash.view') || can('bill.view') || can('invoice.view') || can('transaction.view') || can('advance.view') || can('tax.view') || can('verify.view') || can('e-statement.view') || can('report.view')) && (
                    <div className="space-y-2">
                        <div className="text-xs">
                            {lang.finance}
                        </div>
                        <ul className="space-y-6">
                            {can('petty-cash.view') && (
                                <li>
                                    <Link to="/petty-cash" state={{ back: null, from: null, transition: 'fade' }} className="flex items-center space-x-4">
                                        <div className={`${url === '/petty-cash' ? 'bg-neutral-900 ring-1 ring-offset-2 ring-neutral-900' : 'bg-neutral-700'} p-2 text-white rounded-full`}>
                                            <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" width={24} height={24} viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                <path d="M5 21v-16a2 2 0 0 1 2 -2h10a2 2 0 0 1 2 2v16l-3 -2l-2 2l-2 -2l-2 2l-2 -2l-3 2"></path>
                                                <path d="M14 8h-2.5a1.5 1.5 0 0 0 0 3h1a1.5 1.5 0 0 1 0 3h-2.5m2 0v1.5m0 -9v1.5"></path>
                                            </svg>
                                        </div>
                                        <div>
                                            {lang.title.petty_cashes}
                                        </div>
                                        {url === '/petty-cash' && (<div className="flex-grow border-t border-neutral-900"></div>)}
                                    </Link>
                                </li>
                            )}

                            {can('bill.view') && (
                                <li>
                                    <Link to="/bill" state={{ back: null, from: null, transition: 'fade' }} className="flex items-center space-x-4">
                                        <div className={`${url === '/bill' ? 'bg-neutral-900 ring-[1.5px] ring-offset-2 ring-neutral-900' : 'bg-neutral-700'} p-2 text-white rounded-full`}>
                                            <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" width={24} height={24} viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                <path d="M9 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-12a2 2 0 0 0 -2 -2h-2"></path>
                                                <rect x={9} y={3} width={6} height={4} rx={2}></rect>
                                                <path d="M9 17v-5"></path>
                                                <path d="M12 17v-1"></path>
                                                <path d="M15 17v-3"></path>
                                            </svg>
                                        </div>
                                        <div>
                                            {lang.title.bills}
                                        </div>
                                        {url === '/bill' && (<div className="flex-grow border-t border-neutral-900"></div>)}
                                    </Link>
                                </li>
                            )}

                            {can('invoice.view') && (
                                <li>
                                    <Link to="/invoice" state={{ back: null, from: null, transition: 'fade' }} className="flex items-center space-x-4">
                                        <div className={`${url === '/invoice' ? 'bg-neutral-900 ring-[1.5px] ring-offset-2 ring-neutral-900' : 'bg-neutral-700'} p-2 text-white rounded-full`}>
                                            <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" width={24} height={24} viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                <path d="M14 3v4a1 1 0 0 0 1 1h4"></path>
                                                <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z"></path>
                                                <line x1={9} y1={7} x2={10} y2={7}></line>
                                                <line x1={9} y1={13} x2={15} y2={13}></line>
                                                <line x1={13} y1={17} x2={15} y2={17}></line>
                                            </svg>
                                        </div>
                                        <div>
                                            {lang.title.invoice.list}
                                        </div>
                                        {url === '/invoice' && (<div className="flex-grow border-t border-neutral-900"></div>)}
                                    </Link>
                                </li>
                            )}

                            {can('transaction.view') && (
                                <li>
                                    <Link to="/transaction" state={{ back: null, from: null, transition: 'fade' }} className="flex items-center space-x-4">
                                        <div className={`${url === '/transaction' ? 'bg-neutral-900 ring-[1.5px] ring-offset-2 ring-neutral-900' : 'bg-neutral-700'} p-2 text-white rounded-full`}>
                                            <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" width={24} height={24} viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                <path d="M5 21v-16a2 2 0 0 1 2 -2h10a2 2 0 0 1 2 2v16l-3 -2l-2 2l-2 -2l-2 2l-2 -2l-3 2m4 -14h6m-6 4h6m-2 4h2"></path>
                                            </svg>
                                        </div>
                                        <div>
                                            {lang.title.transaction.list}
                                        </div>
                                        {url === '/transaction' && (<div className="flex-grow border-t border-neutral-900"></div>)}
                                    </Link>
                                </li>
                            )}

                            {can('advance.view') && (
                                <li>
                                    <Link to="/advance-payment" state={{ back: null, from: null, transition: 'fade' }} className="flex items-center space-x-4">
                                        <div className={`${url === '/advance-payment' ? 'bg-neutral-900 ring-[1.5px] ring-offset-2 ring-neutral-900' : 'bg-neutral-700'} p-2 text-white rounded-full`}>
                                            <AdvancePaymentIcon className="w-6 h-6" />
                                        </div>
                                        <div>
                                            {lang.title.advance.list}
                                        </div>
                                        {url === '/advance-payment' && (<div className="flex-grow border-t border-neutral-900"></div>)}
                                    </Link>
                                </li>
                            )}

                            {can('tax.view') && (
                                <li>
                                    <Link to="/tax-payment" state={{ back: null, from: null, transition: 'fade' }} className="flex items-center space-x-4">
                                        <div className={`${url === '/tax-payment' ? 'bg-neutral-900 ring-[1.5px] ring-offset-2 ring-neutral-900' : 'bg-neutral-700'} p-2 text-white rounded-full`}>
                                            <TaxPaymentIcon className="w-6 h-6" />
                                        </div>
                                        <div>
                                            {lang.title.tax.list}
                                        </div>
                                        {url === '/tax-payment' && (<div className="flex-grow border-t border-neutral-900"></div>)}
                                    </Link>
                                </li>
                            )}

                            {can('verify.view') && (
                                <li>
                                    <Link to="/verify-payment" state={{ back: null, from: null, transition: 'fade' }} className="flex items-center space-x-4">
                                        <div className={`${url === '/verify-payment' ? 'bg-neutral-900 ring-[1.5px] ring-offset-2 ring-neutral-900' : 'bg-neutral-700'} p-2 text-white rounded-full`}>
                                            <VerifyPaymentIcon className="w-6 h-6" />
                                        </div>
                                        <div>
                                            {lang.verify}
                                        </div>
                                        {url === '/verify-payment' && (<div className="flex-grow border-t border-neutral-900"></div>)}
                                    </Link>
                                </li>
                            )}

                            {can('e-statement.view') && (
                                <li>
                                    <Link to="/e-statement" state={{ back: null, from: null, transition: 'fade' }} className="flex items-center space-x-4">
                                        <div className={`${url === '/e-statement' ? 'bg-neutral-900 ring-[1.5px] ring-offset-2 ring-neutral-900' : 'bg-neutral-700'} p-2 text-white rounded-full`}>
                                            <FileDollarIcon className="w-6 h-6" />
                                        </div>
                                        <div>
                                            {lang.e_statement}
                                        </div>
                                        {url === '/e-statement' && (<div className="flex-grow border-t border-neutral-900"></div>)}
                                    </Link>
                                </li>
                            )}

                            {can('report.view') && (
                                <li>
                                    <Disclosure>
                                        {({ open }) => (
                                            <>
                                                <Disclosure.Button className="flex items-center justify-between w-full">
                                                    <div className="flex items-center space-x-4">
                                                        <div className={`${(open || url === '/report/cash-flow' || url === '/merchant/fee') ? 'bg-neutral-900 ring-1 ring-offset-2 ring-neutral-900' : 'bg-neutral-700'} p-2 text-white rounded-full`}>
                                                            <ClipboardDataIcon className="w-6 h-6" />
                                                        </div>
                                                        <div>
                                                            {lang.title.report.list}
                                                        </div>
                                                    </div>
                                                    <div className={`${open ? 'rotate-90' : ''} transition-transform p-[2px] border rounded-full border-neutral-300 bg-neutral-200/50`}>
                                                        <ChevronRightIcon className="w-4 h-4" stroke={1} />
                                                    </div>
                                                </Disclosure.Button>
                                                <AnimatePresence mode="wait">
                                                    {open && (
                                                        <motion.div initial={{ height: 0, marginTop: 0, opacity: 0 }} animate={{ height: 'auto', marginTop: '1rem', opacity: 1 }} exit={{ height: 0, marginTop: 0, opacity: 0 }} transition={{ type: "spring", damping: 20 }} className="overflow-hidden">
                                                            <Disclosure.Panel static>
                                                                <ul className="space-y-4">
                                                                    {
                                                                        configuration('enabled_report').find((row) => row.code === 'CashFlow')?.value && (
                                                                            <li>
                                                                                <Link to="/report/cash-flow" state={{ back: null, from: null, transition: 'fade' }} className="flex items-center ml-3 text-xs group">
                                                                                    <div className={`${url === '/report/cash-flow' ? 'bg-neutral-800 border-neutral-800' : 'bg-neutral-400/50 border-neutral-400'} transition w-4 h-4 border rounded-full group-hover:bg-neutral-800 group-hover:border-neutral-800`}></div>
                                                                                    <span className={`${url === '/report/cash-flow' ? 'text-neutral-800' : 'text-neutral-500'} ml-7 group-hover:text-neutral-800 transition`}>{lang.title.report.cash_flow}</span>
                                                                                    {url === '/report/cash-flow' && (<div className="flex-grow ml-4 border-t border-neutral-900"></div>)}
                                                                                </Link>
                                                                            </li>
                                                                        )
                                                                    }
                                                                    {
                                                                        configuration('enabled_report').find((row) => row.code === 'Bill')?.value && (
                                                                            <li>
                                                                                <Link to="/report/bill" state={{ back: null, from: null, transition: 'fade' }} className="flex items-center ml-3 text-xs group">
                                                                                    <div>
                                                                                        <div className={`${url === '/report/bill' ? 'bg-neutral-800 border-neutral-800' : 'bg-neutral-400/50 border-neutral-400'} transition w-4 h-4 border rounded-full group-hover:bg-neutral-800 group-hover:border-neutral-800`}></div>
                                                                                    </div>
                                                                                    <span className={`${url === '/report/bill' ? 'text-neutral-800' : 'text-neutral-500'} ml-7 group-hover:text-neutral-800 transition`}>{lang.title.report.bill}</span>
                                                                                    {url === '/report/bill' && (<div className="flex-grow ml-4 border-t border-neutral-900"></div>)}
                                                                                </Link>
                                                                            </li>
                                                                        )
                                                                    }
                                                                    {
                                                                        configuration('enabled_report').find((row) => row.code === 'PettyCash')?.value && (
                                                                            <li>
                                                                                <Link to="/report/petty-cash" state={{ back: null, from: null, transition: 'fade' }} className="flex items-center ml-3 text-xs group">
                                                                                    <div>
                                                                                        <div className={`${url === '/report/petty-cash' ? 'bg-neutral-800 border-neutral-800' : 'bg-neutral-400/50 border-neutral-400'} transition w-4 h-4 border rounded-full group-hover:bg-neutral-800 group-hover:border-neutral-800`}></div>
                                                                                    </div>
                                                                                    <span className={`${url === '/report/petty-cash' ? 'text-neutral-800' : 'text-neutral-500'} ml-7 group-hover:text-neutral-800 transition`}>{lang.title.report.petty_cash}</span>
                                                                                </Link>
                                                                            </li>
                                                                        )
                                                                    }
                                                                    {
                                                                        configuration('enabled_report').find((row) => row.code === 'OutstandingSupplier')?.value && (
                                                                            <li>
                                                                                <Link to="/report/outstanding-supplier" state={{ back: null, from: null, transition: 'fade' }} className="flex items-center ml-3 text-xs group">
                                                                                    <div>
                                                                                        <div className={`${url === '/report/outstanding-supplier' ? 'bg-neutral-800 border-neutral-800' : 'bg-neutral-400/50 border-neutral-400'} transition w-4 h-4 border rounded-full group-hover:bg-neutral-800 group-hover:border-neutral-800`}></div>
                                                                                    </div>
                                                                                    <span className={`${url === '/report/outstanding-supplier' ? 'text-neutral-800' : 'text-neutral-500'} ml-7 group-hover:text-neutral-800 transition`}>Outstanding Supplier</span>
                                                                                </Link>
                                                                            </li>
                                                                        )
                                                                    }
                                                                    {
                                                                        (configuration('enabled_report').find((row) => row.code === 'LedgerSupplier')?.value || true) && (
                                                                            <li>
                                                                                <Link to="/report/ledger-supplier" state={{ back: null, from: null, transition: 'fade' }} className="flex items-center ml-3 text-xs group">
                                                                                    <div>
                                                                                        <div className={`${url === '/report/ledger-supplier' ? 'bg-neutral-800 border-neutral-800' : 'bg-neutral-400/50 border-neutral-400'} transition w-4 h-4 border rounded-full group-hover:bg-neutral-800 group-hover:border-neutral-800`}></div>
                                                                                    </div>
                                                                                    <span className={`${url === '/report/ledger-supplier' ? 'text-neutral-800' : 'text-neutral-500'} ml-7 group-hover:text-neutral-800 transition`}>Ledger Supplier</span>
                                                                                </Link>
                                                                            </li>
                                                                        )
                                                                    }
                                                                    {
                                                                        (configuration('enabled_report').find((row) => row.code === 'HutangOutstandingKaryawan')?.value || true) && (
                                                                            <li>
                                                                                <Link to="/report/outstanding-karyawan/hutang" state={{ back: null, from: null, transition: 'fade' }} className="flex items-center ml-3 text-xs group">
                                                                                    <div>
                                                                                        <div className={`${url === '/report/outstanding-karyawan/hutang' ? 'bg-neutral-800 border-neutral-800' : 'bg-neutral-400/50 border-neutral-400'} transition w-4 h-4 border rounded-full group-hover:bg-neutral-800 group-hover:border-neutral-800`}></div>
                                                                                    </div>
                                                                                    <span className={`${url === '/report/outstanding-karyawan/hutang' ? 'text-neutral-800' : 'text-neutral-500'} ml-7 group-hover:text-neutral-800 transition`}>Hutang Outstanding Karyawan</span>
                                                                                </Link>
                                                                            </li>
                                                                        )
                                                                    }
                                                                    {
                                                                        (configuration('enabled_report').find((row) => row.code === 'PiutangOutstandingKaryawan')?.value || true) && (
                                                                            <li>
                                                                                <Link to="/report/outstanding-karyawan/piutang" state={{ back: null, from: null, transition: 'fade' }} className="flex items-center ml-3 text-xs group">
                                                                                    <div>
                                                                                        <div className={`${url === '/report/outstanding-karyawan/piutang' ? 'bg-neutral-800 border-neutral-800' : 'bg-neutral-400/50 border-neutral-400'} transition w-4 h-4 border rounded-full group-hover:bg-neutral-800 group-hover:border-neutral-800`}></div>
                                                                                    </div>
                                                                                    <span className={`${url === '/report/outstanding-karyawan/piutang' ? 'text-neutral-800' : 'text-neutral-500'} ml-7 group-hover:text-neutral-800 transition`}>Piutang Outstanding Karyawan</span>
                                                                                </Link>
                                                                            </li>
                                                                        )
                                                                    }
                                                                    {
                                                                        (configuration('enabled_report').find((row) => row.code === 'HutangLedgerKaryawan')?.value || true) && (
                                                                            <li>
                                                                                <Link to="/report/ledger-karyawan/hutang" state={{ back: null, from: null, transition: 'fade' }} className="flex items-center ml-3 text-xs group">
                                                                                    <div>
                                                                                        <div className={`${url === '/report/ledger-karyawan/hutang' ? 'bg-neutral-800 border-neutral-800' : 'bg-neutral-400/50 border-neutral-400'} transition w-4 h-4 border rounded-full group-hover:bg-neutral-800 group-hover:border-neutral-800`}></div>
                                                                                    </div>
                                                                                    <span className={`${url === '/report/ledger-karyawan/hutang' ? 'text-neutral-800' : 'text-neutral-500'} ml-7 group-hover:text-neutral-800 transition`}>Hutang Ledger Karyawan</span>
                                                                                </Link>
                                                                            </li>
                                                                        )
                                                                    }
                                                                    {
                                                                        (configuration('enabled_report').find((row) => row.code === 'PiutangLedgerKaryawan')?.value || true) && (
                                                                            <li>
                                                                                <Link to="/report/ledger-karyawan/piutang" state={{ back: null, from: null, transition: 'fade' }} className="flex items-center ml-3 text-xs group">
                                                                                    <div>
                                                                                        <div className={`${url === '/report/ledger-karyawan/piutang' ? 'bg-neutral-800 border-neutral-800' : 'bg-neutral-400/50 border-neutral-400'} transition w-4 h-4 border rounded-full group-hover:bg-neutral-800 group-hover:border-neutral-800`}></div>
                                                                                    </div>
                                                                                    <span className={`${url === '/report/ledger-karyawan/piutang' ? 'text-neutral-800' : 'text-neutral-500'} ml-7 group-hover:text-neutral-800 transition`}>Piutang Ledger Karyawan</span>
                                                                                </Link>
                                                                            </li>
                                                                        )
                                                                    }
                                                                    {
                                                                        (configuration('enabled_report').find((row) => row.code === 'BiayaOutlet')?.value || true) && (
                                                                            <li>
                                                                                <Link to="/report/biaya-outlet" state={{ back: null, from: null, transition: 'fade' }} className="flex items-center ml-3 text-xs group">
                                                                                    <div>
                                                                                        <div className={`${url === '/report/biaya-outlet' ? 'bg-neutral-800 border-neutral-800' : 'bg-neutral-400/50 border-neutral-400'} transition w-4 h-4 border rounded-full group-hover:bg-neutral-800 group-hover:border-neutral-800`}></div>
                                                                                    </div>
                                                                                    <span className={`${url === '/report/biaya-outlet' ? 'text-neutral-800' : 'text-neutral-500'} ml-7 group-hover:text-neutral-800 transition`}>Biaya Outlet</span>
                                                                                </Link>
                                                                            </li>
                                                                        )
                                                                    }
                                                                    {
                                                                        (configuration('enabled_report').find((row) => row.code === 'BiayaOutletDetail')?.value || true) && (
                                                                            <li>
                                                                                <Link to="/report/biaya-outlet/detail" state={{ back: null, from: null, transition: 'fade' }} className="flex items-center ml-3 text-xs group">
                                                                                    <div>
                                                                                        <div className={`${url === '/report/biaya-outlet/detail' ? 'bg-neutral-800 border-neutral-800' : 'bg-neutral-400/50 border-neutral-400'} transition w-4 h-4 border rounded-full group-hover:bg-neutral-800 group-hover:border-neutral-800`}></div>
                                                                                    </div>
                                                                                    <span className={`${url === '/report/biaya-outlet/detail' ? 'text-neutral-800' : 'text-neutral-500'} ml-7 group-hover:text-neutral-800 transition`}>Biaya Outlet Detail</span>
                                                                                </Link>
                                                                            </li>
                                                                        )
                                                                    }
                                                                    {
                                                                        (configuration('enabled_report').find((row) => row.code === 'BiayaGlobal')?.value || true) && (
                                                                            <li>
                                                                                <Link to="/report/biaya-global" state={{ back: null, from: null, transition: 'fade' }} className="flex items-center ml-3 text-xs group">
                                                                                    <div>
                                                                                        <div className={`${url === '/report/biaya-global' ? 'bg-neutral-800 border-neutral-800' : 'bg-neutral-400/50 border-neutral-400'} transition w-4 h-4 border rounded-full group-hover:bg-neutral-800 group-hover:border-neutral-800`}></div>
                                                                                    </div>
                                                                                    <span className={`${url === '/report/biaya-global' ? 'text-neutral-800' : 'text-neutral-500'} ml-7 group-hover:text-neutral-800 transition`}>Biaya Global</span>
                                                                                </Link>
                                                                            </li>
                                                                        )
                                                                    }
                                                                    {
                                                                        (configuration('enabled_report').find((row) => row.code === 'BiayaGlobalDetail')?.value || true) && (
                                                                            <li>
                                                                                <Link to="/report/biaya-global/detail" state={{ back: null, from: null, transition: 'fade' }} className="flex items-center ml-3 text-xs group">
                                                                                    <div>
                                                                                        <div className={`${url === '/report/biaya-global/detail' ? 'bg-neutral-800 border-neutral-800' : 'bg-neutral-400/50 border-neutral-400'} transition w-4 h-4 border rounded-full group-hover:bg-neutral-800 group-hover:border-neutral-800`}></div>
                                                                                    </div>
                                                                                    <span className={`${url === '/report/biaya-global/detail' ? 'text-neutral-800' : 'text-neutral-500'} ml-7 group-hover:text-neutral-800 transition`}>Biaya Global Detail</span>
                                                                                </Link>
                                                                            </li>
                                                                        )
                                                                    }
                                                                    {
                                                                        (configuration('enabled_report').find((row) => row.code === 'OutstandingKonsumen')?.value || true) && (
                                                                            <li>
                                                                                <Link to="/report/outstanding-konsumen" state={{ back: null, from: null, transition: 'fade' }} className="flex items-center ml-3 text-xs group">
                                                                                    <div>
                                                                                        <div className={`${url === '/report/outstanding-konsumen' ? 'bg-neutral-800 border-neutral-800' : 'bg-neutral-400/50 border-neutral-400'} transition w-4 h-4 border rounded-full group-hover:bg-neutral-800 group-hover:border-neutral-800`}></div>
                                                                                    </div>
                                                                                    <span className={`${url === '/report/outstanding-konsumen' ? 'text-neutral-800' : 'text-neutral-500'} ml-7 group-hover:text-neutral-800 transition`}>Outstanding Konsumen</span>
                                                                                </Link>
                                                                            </li>
                                                                        )
                                                                    }
                                                                    {
                                                                        (configuration('enabled_report').find((row) => row.code === 'LedgerKonsumen')?.value || true) && (
                                                                            <li>
                                                                                <Link to="/report/ledger-konsumen" state={{ back: null, from: null, transition: 'fade' }} className="flex items-center ml-3 text-xs group">
                                                                                    <div>
                                                                                        <div className={`${url === '/report/ledger-konsumen' ? 'bg-neutral-800 border-neutral-800' : 'bg-neutral-400/50 border-neutral-400'} transition w-4 h-4 border rounded-full group-hover:bg-neutral-800 group-hover:border-neutral-800`}></div>
                                                                                    </div>
                                                                                    <span className={`${url === '/report/ledger-konsumen' ? 'text-neutral-800' : 'text-neutral-500'} ml-7 group-hover:text-neutral-800 transition`}>Ledger Konsumen</span>
                                                                                </Link>
                                                                            </li>
                                                                        )
                                                                    }
                                                                </ul>
                                                            </Disclosure.Panel>
                                                        </motion.div>
                                                    )}
                                                </AnimatePresence>
                                            </>
                                        )}
                                    </Disclosure>
                                </li>
                            )}
                        </ul>
                    </div>
                )}

                {(can('supplier.view') || can('customer.view') || can('merchant.view') || can('super')) && (
                    <div className="space-y-2">
                        <div className="text-xs">
                            {lang.data}
                        </div>
                        <ul className="space-y-6">
                            {can('supplier.view') && (
                                <li>
                                    <Link to="/supplier" state={{ back: null, from: null, transition: 'fade' }} className="flex items-center space-x-4">
                                        <div className={`${url === '/supplier' ? 'bg-neutral-900 ring-[1.5px] ring-offset-2 ring-neutral-900' : 'bg-neutral-700'} p-2 text-white rounded-full`}>
                                            <SupplierIcon className="w-6 h-6" />
                                        </div>
                                        <div>
                                            {lang.title.supplier.list}
                                        </div>
                                        {url === '/supplier' && (<div className="flex-grow border-t border-neutral-900"></div>)}
                                    </Link>
                                </li>
                            )}

                            {can('customer.view') && (
                                <li>
                                    <Link to="/customer" state={{ back: null, from: null, transition: 'fade' }} className="flex items-center space-x-4">
                                        <div className={`${url === '/customer' ? 'bg-neutral-900 ring-[1.5px] ring-offset-2 ring-neutral-900' : 'bg-neutral-700'} p-2 text-white rounded-full`}>
                                            <UsersIcon className="w-6 h-6" />
                                        </div>
                                        <div>
                                            {lang.title.customer.list}
                                        </div>
                                        {url === '/customer' && (<div className="flex-grow border-t border-neutral-900"></div>)}
                                    </Link>
                                </li>
                            )}

                            {can('super') && (
                                <>
                                    <li>
                                        <Link to="/branch" state={{ back: null, from: null, transition: 'fade' }} className="flex items-center space-x-4">
                                            <div className={`${url === '/branch' ? 'bg-neutral-900 ring-[1.5px] ring-offset-2 ring-neutral-900' : 'bg-neutral-700'} p-2 text-white rounded-full`}>
                                                <BranchIcon className="w-6 h-6" />
                                            </div>
                                            <div>
                                                {lang.title.branch.list}
                                            </div>
                                            {url === '/branch' && (<div className="flex-grow border-t border-neutral-900"></div>)}
                                        </Link>
                                    </li>

                                    <li>
                                        <Link to="/user" state={{ back: null, from: null, transition: 'fade' }} className="flex items-center space-x-4">
                                            <div className={`${url === '/user' ? 'bg-neutral-900 ring-[1.5px] ring-offset-2 ring-neutral-900' : 'bg-neutral-700'} p-2 text-white rounded-full`}>
                                                <UserIcon className="w-6 h-6" />
                                            </div>
                                            <div>
                                                {lang.title.user.list}
                                            </div>
                                            {url === '/user' && (<div className="flex-grow border-t border-neutral-900"></div>)}
                                        </Link>
                                    </li>

                                    <li>
                                        <Link to="/role" state={{ back: null, from: null, transition: 'fade' }} className="flex items-center space-x-4">
                                            <div className={`${url === '/role' ? 'bg-neutral-900 ring-[1.5px] ring-offset-2 ring-neutral-900' : 'bg-neutral-700'} p-2 text-white rounded-full`}>
                                                <LockAccessIcon className="w-6 h-6" />
                                            </div>
                                            <div>
                                                {lang.title.role.list}
                                            </div>
                                            {url === '/role' && (<div className="flex-grow border-t border-neutral-900"></div>)}
                                        </Link>
                                    </li>

                                    <li>
                                        <Link to="/role-user-branch" state={{ back: null, from: null, transition: 'fade' }} className="flex items-center space-x-4">
                                            <div className={`${url === '/role-user-branch' ? 'bg-neutral-900 ring-[1.5px] ring-offset-2 ring-neutral-900' : 'bg-neutral-700'} p-2 text-white rounded-full`}>
                                                <RoleUserBranchIcon className="w-6 h-6" />
                                            </div>
                                            <div>
                                                {lang.map}
                                            </div>
                                            {url === '/role-user-branch' && (<div className="flex-grow border-t border-neutral-900"></div>)}
                                        </Link>
                                    </li>
                                </>
                            )}
                            {can('merchant.view') && (
                                <li>
                                    <Link to="/merchant" state={{ back: null, from: null, transition: 'fade' }} className="flex items-center space-x-4">
                                        <div className={`${url === '/merchant' ? 'bg-neutral-900 ring-[1.5px] ring-offset-2 ring-neutral-900' : 'bg-neutral-700'} p-2 text-white rounded-full`}>
                                            <StoreIcon className="w-6 h-6" />
                                        </div>
                                        <div>
                                            {lang.title.merchant.list}
                                        </div>
                                        {url === '/merchant' && (<div className="flex-grow border-t border-neutral-900"></div>)}
                                    </Link>
                                </li>
                            )}
                        </ul>
                    </div>
                )}
            </nav>
        </motion.div>
    )
}

export default Sidebar