import { useState } from 'react'
import { Modal } from 'components'
import axios from 'axios'
import { useAuth } from 'contexts/auth-context'
import nProgress from 'nprogress'
import toast from 'react-hot-toast'
import { lang } from 'config'
import Label from 'components/forms/label'
import ErrorMessage from 'components/forms/error-message'
import Input from 'components/forms/input'
import { CloudUploadIcon } from 'components/icons'

const Content = ({ success }) => {
    const { user, isSuper } = useAuth()
    
    const [file, setFile] = useState()

    const [errors, setErrors] = useState({})

    const handleSubmit = (e) => {
        e.preventDefault()

        nProgress.start()

        axios.post(`${process.env.REACT_APP_BACKEND_URL}/e-statement/upload`, {
            file
        }, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        }).then((response) => {
            success()
            toast.success(response.data.message)
            nProgress.done()
        }).catch(error => {
            if (error.response.status === 422) {
                setErrors(error.response.data.errors)
            }
            toast.error(error.response.data.message)
            console.log(error)
            nProgress.done()
        })
    }

    return (
        <form onSubmit={handleSubmit} className="mt-8 space-y-8">
            <div className="grid grid-cols-2 gap-4">
                <div className="col-span-2">
                    <Label htmlFor="file" value={lang.upload} />
                    <Input accept="text/csv" type="file" onChange={(e) => setFile(e.target.files[0])} error={errors.file} value={null} id="file" />
                    <ErrorMessage error={errors.file} />
                </div>
            </div>
            <div className="text-xs">
                <button type="submit" className="items-center px-6 py-3 text-white transition bg-neutral-800 rounded-xl active:hover:scale-90">
                    <span>{lang.upload}</span>
                </button>
            </div>
        </form>
    )
}

const Upload = ({ success }) => {
    const [isOpen, setIsOpen] = useState(false)

    const openModal = () => setIsOpen(true)
    const closeModal = () => setIsOpen(false)

    const handleSuccess = () => {
        closeModal()
        success()
    }

    return (
        <>
            <button onClick={openModal} className="inline-flex items-center px-4 py-3 space-x-2 text-white transition bg-neutral-800 rounded-xl active:hover:scale-90">
                <CloudUploadIcon className="w-4 h-4" stroke={1.5} />
                <span>Upload CSV</span>
            </button>
            <Modal isOpen={isOpen} onClose={closeModal} title={`${lang.upload} ${lang.csv_data}`} content={<Content success={handleSuccess} />} />
        </>
    )
}

export default Upload